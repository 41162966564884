import React, {FC, useState} from 'react';
import {ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {relationshipData} from "../assets/modules/utils";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import AppStore from "../../../store/AppStore";
import prompt_svg from "../../../assets/icons/prompt.svg";
import {Textarea} from "../../../components/FormControls";
import chevron_right from "../../../assets/icons/chevron_right.svg";
import Button from "../../../components/Button";
import StepTextareaWrapper from "../containers/StepTextareaWrapper";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step11Relationship: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();
  const [text, setText] = useState(relationshipData[data?.relationship!] ? '' : data?.relationship || '');

  const handleStepChange = (relationship: ICreateModelRequest['relationship']) => () => {
    onStepChange({relationship});
  }

  return (
    <StepTextareaWrapper
      value={text}
      onChange={setText}
      onClick={handleStepChange(text)}
      className='row g-3'
      placeholder={'RELATIONSHIP_PLACEHOLDER'}
    >
      {Object.entries(relationshipData).map(([value, item], i) => (
        <div className='col-6 col-md-4' key={i}>
          <StepItem
            onClick={handleStepChange(value)}
            active={!text && data?.relationship === value}
            className='flex-column h-100'
          >
            <div className='item__personality_title'>
              <span className='me-2'>{item.icon}</span>
              <span className='text-14'>{t(item[AppStore.gender])}</span>
            </div>
          </StepItem>
        </div>
      ))}
    </StepTextareaWrapper>

  );
})

export default Step11Relationship;