import React, {FC} from 'react';
import {EHairColor, ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {
  getModalHairColor,
  preloadSrcBodyType
} from "../assets/modules/utils";
import PreloadImages from "../../../components/PreloadImages";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step5HairColor: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const handleStepChange = (hairColor: ICreateModelRequest['hairColor']) => () => {
    onStepChange({hairColor});
  }

  return (
    <>
      <PreloadImages images={preloadSrcBodyType}/>
      <div className='create__ai_list-hairColor text-14'>
        {Object.values(EHairColor).map((hairColor) => (
          <StepItem size='xs'
                    onClick={handleStepChange(hairColor)}
                    key={hairColor}
                    active={hairColor === data?.hairColor}
          >
            <div className="create__ai_list-color" style={{background: getModalHairColor(hairColor)}}/>
              <span className='text-truncate'>{t(hairColor.toUpperCase())}</span>
          </StepItem>
        ))}
      </div>
    </>
  );
})

export default Step5HairColor;
