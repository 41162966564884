import React, {Suspense, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react';
import useUtm from './hooks/useUtm';
import useToTop from './hooks/useToTop';
import {initFirebase} from './modules/firebase';
import AppStore from './store/AppStore';
import HomeStore from './store/HomeStore';
import PreloadImages from './components/PreloadImages';
import Footer from './containers/Footer';
import {preloadSrcEthnicity, preloadSrcStyle} from './pages/CreateAIPage/assets/modules/utils';
import Joyride from 'react-joyride';
import CustomTooltip from './containers/CustomTooltip';
import {useTelegram} from './hooks/useTelegram';
import {useTranslation} from 'react-i18next';
import {openWarning18Modal} from './modals/Settings/Warning18Modal';
import {isSmallDevice} from './modules/utils';

import HomePage from "./pages/HomePage";
import Header from "./containers/Header";
import CreateAIPage from "./pages/CreateAIPage";

// const ChatPage = React.lazy(() => import('./pages/ChatPage'));
const MyAIPage = React.lazy(() => import('./pages/MyAIPage'));
const GalleryPage = React.lazy(() => import('./pages/GalleryPage'));
const PaymentStatusPage = React.lazy(() => import('./pages/PaymentStatusPage'));
const GenerateImagePage = React.lazy(() => import('./pages/GenerateImagePage'));
const BillingAddressPage = React.lazy(() => import('./pages/BillingAddressPage'));
// const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const GeneratePage = React.lazy(() => import('./pages/GeneratePage'));
const PaymentPage = React.lazy(() => import('./pages/PaymentPage'));
const RefPage = React.lazy(() => import('./pages/RefPage'));
const AuthPage = React.lazy(() => import('./pages/AuthPage'));
// const ModelProfilePage = React.lazy(() => import('./pages/ModelProfilePage'));
const LegalInformationPage = React.lazy(() => import('./pages/LegalInformationPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
// const ChatCreateConversation = React.lazy(() => import("./pages/ChatPage/components/ChatCreateConversation"));
const ConversationPage = React.lazy(() => import('./pages/ConversationPage'));

const App = observer(() => {
  const {t} = useTranslation();
  const {tg, tgQueryId} = useTelegram();
  useToTop();
  useUtm();

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [runOnboard, setRunOnboard] = useState(false);

  const steps = [
    {
      disableBeacon: true,
      target: '#head__coin_btn',
      content: t('HELPER_TEXT_1')
    }
  ];

  useEffect(() => {
    if (!tgQueryId) {
      initFirebase();
    } else {
      document.getElementById('root')?.classList.add('tg__app');
      tg.ready();
      tg.expand();
    }
    AppStore.init().then(showCoinsHelper);
    window.addEventListener('resize', resizeScreen);
    return () => window.removeEventListener('resize', resizeScreen);
  }, []);

  useEffect(() => {
    if (AppStore.ready) HomeStore.init();
  }, [AppStore.gender, AppStore.ready]);

  const showCoinsHelper = async () => {
    if (!localStorage.getItem('confirm18+')) {
      await openWarning18Modal();
    }
    if (!localStorage.getItem('coinHelper')) {
      setRunOnboard(true);
      localStorage.setItem('coinHelper', '1');
    }
  }

  const resizeScreen = () => {
    setInnerWidth(window.innerWidth);
  };

  return (
    <>
      <Joyride
        spotlightPadding={4}
        continuous={false}
        tooltipComponent={CustomTooltip}
        run={runOnboard}
        steps={steps}
      />
      {innerWidth >= 992 && (
        <Header/>
      )}
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path='/r/:id' element={<RefPage/>}/>
          <Route path='/' element={<HomePage/>}/>

          <Route path='/conversation/*' element={<ConversationPage/>}/>
          {/*<Route path='/conversation/:id' element={<ChatPage/>}/>*/}

          <Route path='/create-ai' element={<CreateAIPage/>}/>

          <Route path='/premium/*' element={<PaymentPage/>}/>
          <Route path='/payment-status' element={<PaymentStatusPage/>}/>

          <Route path='/request-photo' element={<GenerateImagePage/>}/>
          <Route path='/generate/*' element={<GeneratePage/>}/>

          <Route path='/gallery' element={<GalleryPage/>}/>

          <Route path='/companions' element={<MyAIPage/>}/>

          <Route path='/billing-address' element={<BillingAddressPage/>}/>

          <Route path='/profile' element={<ProfilePage/>}/>
          <Route path='/legal-information/*' element={<LegalInformationPage/>}/>
          {!tgQueryId
            ?
            <>
              <Route path='/auth/*' element={<AuthPage/>}/>
            </>
            :
            null
          }
          <Route path='*' element={<Navigate to='/' replace/>}/>
          {/*<Route path='*' element={<NotFoundPage/>}/>*/}
        </Routes>
      </Suspense>
      <Footer/>
      <PreloadImages images={[...preloadSrcStyle, ...preloadSrcEthnicity]}/>
    </>
  );
});

export default App;