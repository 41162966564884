import React, {FC} from 'react';
import image_1 from '../../assets/images/upload_image_1.png';
import image_2 from '../../assets/images/upload_image_2.png';

import {useTranslation} from "react-i18next";
import {InputFile} from "../../../../components/FormControls";
import {IAsset} from "../../../../modules/rest";

interface Props {
  image?: IAsset|null;
  onChange: (image?: IAsset|null) => void;
  onClick: () => void;
}

const CreateAiUpload: FC<Props> = ({image, onChange, onClick}) => {
  const {t} = useTranslation();

  const handleClick = (e: any) => {
    if(image) {
      e.stopPropagation();
    }
    onClick()
  }

  return (
    <div className={`create__ai_upload-container${!image ? ' empty' : ''}`}>
      <div className={`create__ai_upload`} onClick={onClick}>
        <div className="create__ai_upload-next"><span>{t('NEXT')}</span></div>
        <div className="create__upload_image">
          <div className="create__upload_image-left">
            <img src={image_1} alt="photo"/>
          </div>
          <div className="create__upload_image-right">
            <div className="create__upload_image-before">
              <img src={image_1} alt="photo"/>
            </div>
            <div className="create__upload_image-after">
              <img src={image_2} alt="photo"/>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column justify-content-between flex-1'>
          <div>
            <div className="create__ai_upload-title">{t('FIND_SIMILAR_COMPANION_TITLE')}</div>
            <p className='mt-2 text-dark-14'>{t('FIND_SIMILAR_COMPANION_TEXT')}</p>
          </div>
          <div className='create__ai_upload-input' onClick={(e) => e.stopPropagation()}>
            <InputFile
              id={'create__upload_input'}
              className='w-100'
              accept="image/jpeg"
              value={image}
              onChange={onChange}
              preview
              deletable
            />
          </div>
        </div>
      </div>
      <label htmlFor="create__upload_input" className='create__ai_upload-label' />
    </div>
  );
}

export default CreateAiUpload;