import {RestAPI} from './rest';
import AppStore from "../store/AppStore";
import i18next from "i18next";

const isDev = !!window.location.host.match(/^(localhost|127\.|192\.|mini-app-dev)/);
// const backendUrl = isDev ? 'http://192.168.1.223:8020' : `https://app.noodz.ai`;
// const backendUrl = isDev ? 'http://redstream.by:8020' : `https://api.noodz.ai`;

const backendUrl = isDev ? 'https://api-dev.noodz.ai' : `https://api.noodz.ai`;
// const backendUrl =   `https://api.noodz.ai`;
const staticUrl = isDev ? 'https://static-dev.noodz.ai' : `https://static.noodz.ai`;
// const wsUrl = isDev ? 'ws://192.168.1.223:5020/connection/websocket' : 'wss://wss.noodz.ai/connection/websocket';
const wsUrl =   'wss://wss.noodz.ai/connection/websocket';
const API = new RestAPI(backendUrl, isDev);

API.setAuthErrorHandler(() => {
  AppStore.logout()
  throw new Error()
  // window.localStorage.removeItem('token');
  // window.location.href = '/';
})

API.setHeadersHandler((headers) => {
  headers['Accept-Language'] = i18next.language;
});

export {API, isDev, backendUrl, wsUrl, staticUrl};
