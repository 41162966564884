import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import pause_svg from "./Message/assets/icons/pause.svg";
import play_svg from "./Message/assets/icons/play.svg";

interface Props {
  onClick: (e: any) => void;
  isPlaying?: boolean;
  className?: string
  accent?: boolean
}

const PlayBtn: FC<Props> = ({onClick, isPlaying, className, accent}) => {
  return (
    <div onClick={onClick} className={`play-btn-container ${className || ''}`}>
      <ReactSVG src={isPlaying ? pause_svg : play_svg} className={`react-icon play-btn${isPlaying ? ' active' : ''}${accent ? ' accent' : ''}`}/>
    </div>
  );
}

export default PlayBtn;
