import React, {FC, HTMLAttributes} from 'react';
import {thumb} from "../../modules/utils";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {ReactSVG} from "react-svg";
import user_svg from '../../assets/icons/user.svg';
import user_svg_2 from '../../assets/icons/user_long.svg';

interface Props extends HTMLAttributes<any> {
  image?: string;
  className?: string;
  user?: boolean;
  secondaryIcon?: boolean;
  size?: 'xxl'|'xl'|'lg'|'sm'|'xs'|'xxs'
}

const Avatar: FC<Props> = observer(({image, size, className, user, secondaryIcon, ...props}) => {
  return (
    <div className={`avatar ${user || !image ? ' user' : ''} ${size || ''} ${className || ''}`} {...props}>
      {image ? <img src={image} alt="avatar"/>
        :
        user
          ?
          // <ReactSVG src={user_svg} className='react-icon'/>
          AppStore.user?.avatar
            ?
            <img src={thumb(AppStore.user.avatar, 280)} alt="avatar"/>
            :
            secondaryIcon
            ?
              <ReactSVG src={user_svg_2} className='react-icon user__icon_secondary'/>
              :

            <ReactSVG src={user_svg} className='react-icon'/>
          :
          null
      }
    </div>
  );
})

export default Avatar;