import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {ELanguage} from "./modules/rest";
import Backend from 'i18next-http-backend';

import moment from "moment";
import 'moment/locale/ru'
import 'moment/locale/es'
import 'moment/locale/en-gb'


export const availableLanguages = Object.values(ELanguage);

export const getLang = (ln) => {
  if (!ln) ln = localStorage.getItem('ln') || ( window?.navigator?.userLanguage || window.navigator.language ).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) ln = 'ru';
  ln = availableLanguages.includes(ln) ? ln : 'en';
  moment.locale(ln);
  return ln;
}

i18n
  .use(Backend) // используем backend для загрузки переводов
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: getLang(),
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json?3', // путь к файлам переводов
    },
  });

export default i18n;