import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {HeaderSecondary} from "../containers/Header";
import Button from "../components/Button";
import * as Sentry from "@sentry/react";

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: FC<Props> = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation();

  useEffect(() => {
    Sentry.captureException(error);
  }, []);

  // document.querySelector('#form').scrollIntoView({behavior: 'smooth'});
  return (
    <>
      <HeaderSecondary title='ERROR'/>
      <main className='align-items-center'>
        <h4>{t('ERROR_TEXT')}</h4>
        <Button
          className='w-100 mt-4'
          style={{maxWidth: 320}}
          onClick={() => window.location.replace('/')}
          title={'START_OVER'}
        />

        {/*<div className="alert alert-danger mt-5" style={{width: 800}}>*/}
        {/*<p className="lead"> {error.message}</p>*/}
        {/*<details className="cursor-pointer mb-3">*/}
        {/*  <pre className="p-3 border-1 bg-light">{error.stack}</pre>*/}
        {/*</details>*/}
        {/*</div>*/}
      </main>
    </>
  );
};

export default ErrorFallback;
