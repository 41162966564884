import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IUtm } from '../modules/rest';

const useUtm = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const queryAgev=  query.get('agev');
    if(queryAgev === '0'){
      localStorage.setItem('confirm18+', '1');
    }
    const storageUtm = localStorage.getItem('utm');
    if(!storageUtm) {
      const utm: IUtm = {
        utm_source: query.get('utm_source') || null,
        utm_medium: query.get('utm_medium') || null,
        utm_campaign: query.get('utm_campaign') || null,
        utm_content: query.get('utm_content') || null,
        utm_term: query.get('utm_term') || null,
        partner: query.get('partner') || null,
        pub_id: query.get('pub_id') || null,
        click_id: query.get('click_id') || null,
        campaign_id: query.get('campaign_id') || null,
        sub1: query.get('sub1') || null,
        sub2: query.get('sub2') || null,
        sub3: query.get('sub3') || null,
        sub4: query.get('sub4') || null,
      };
      localStorage.setItem('utm', JSON.stringify(utm));
    }

  }, []);
};

export default useUtm;
