import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {Link} from "react-router-dom";

interface Props {
}

const TermsOfServicePage: FC<Props> = () => {
  return (
     <LegalInformationPageWrapper title='TERMS_OF_SERVICE' date='10/09/2024'>
       <p>Noodz.ai (the “WEBSITE”). These Terms of Service (the “TOS”) set forth the legally binding terms for your use of the noodz.ai website (WEBSITE) owned and operated by STELLARMART LIMITED, duly incorporated in Hong Kong having its address at ROOM 511 5/F MING SANG IND BLDG 19-21 HING YIP STREET Kwun Tong and registration number 76903430. By accessing and/or using the noodz.ai website you agree to be bound by these TOS. You understand and agree that we will treat your access and/or use of the WEBSITE as acceptance of our TOS and of all other Terms and Policies published on <Link to="/">https://noodz.ai</Link>. In the event that you do not agree with our TOS or any other Terms and Policies please cease using our WEBSITE and refrain from accessing any part of our Services. The WEBSITE is intended for personal and non-commercial use only. You agree not to use the WEBSITE for any illegal or unauthorized purpose. For purposes of the TOS “you” and “your” means you as the user of the WEBSITE.</p>
       <h4>1. General</h4>
       <p>Noodz.ai is an online platform that uses artificial intelligence (“AI”) algorithms to generate virtual characters (AI Character) and a variety of images of the virtual characters per users' requests. The WEBSITE can also generate other media including but not limited to images, videos, and chats (the “Services”). Parts of the Services offered by the WEBSITE may require you to create a user account and sign up for subscriptions. To begin with, you need to either pick an AI character you wish to interact with or generate your own AI character (both physical and personality characteristics) using our algorithms. You can then start to interact with your selected character(s).</p>

       <h4>1.1 Account</h4>
       <p>Parts of the Services offered by the WEBSITE may require you to create a user account using your email and password (the “Protected Areas”). In the event of accessing Protected Areas, you agree to access only using your registered email address and password. You can sign-up (register) or log in using your email address. You hereby represent and warrant that all information you submit to create a user account is true and correct, and you are given full rights to submit such information. You agree to update any information associated with your user account (including but not limited to your email, payment information, subscriptions, or other supplemental information as necessary) so that it remains current, accurate, and correct at all times. You agree to protect the confidentiality of your user account, not to share your account access, and not to disclose your password to any third party. You agree that you are fully responsible for all activities occurring under your user account.</p>

       <h4>1.2 Subscription</h4>
       <p>We retain the right, at our sole discretion, to provide some Services that will be available only for paid subscribers. The subscription will begin after the initial payment and the payment should be performed pursuant to the fee terms presented on our website at the time of purchase. You are responsible for payment of all fees, charges, and taxes (if required by law) related to the transaction.</p>

       <h4>1.3 User Safety</h4>
       <p>At STELLARMART LIMITED, we prioritize the safety and well-being of our users. We strongly advise all users to exercise caution and refrain from disclosing sensitive personal information during interactions with AI Characters. This includes but is not limited to financial details, addresses, contact information, or passwords. While we implement security measures to safeguard user data, we cannot guarantee the security of information shared during interactions. Users are solely responsible for protecting their personal information and should be aware of potential risks associated with online conversations.</p>

       <h4>1.4 Warranties</h4>
       <p>You understand and warrant that:</p>
       <ul>
         <li>a. You are fully and duly authorized to enter into these TOS if you are doing so on behalf of another person.</li>
         <li>b. You are of the legal age in the jurisdiction of your place of domicile to form a binding contract with us.</li>
         <li>c. If you are under the legal age in your jurisdiction, you shall not access or use our Services.</li>
       </ul>

       <h4>2. Underage Policy</h4>
       <p>Please consult the Underage Policy document.</p>

       <h4>3. Intellectual Property</h4>
       <p>The intellectual property in the WEBSITE and embedded materials (including without limitation technology, systems, files, documents, text, photographs, information, images, videos, audios, and software) in Noodz.ai are owned by or licensed to STELLARMART LIMITED. Users are prohibited from reproducing, modifying, distributing, or using any intellectual property without explicit authorization.</p>

       <h4>4. Your Content</h4>
       <p>You may provide input within the WEBSITE (“Input”) and receive output from the Services provided by the WEBSITE based on the Input (“Output”). Input and Output are collectively referred to as “Content.” You retain intellectual property ownership rights over the Input, but we require a license from you to use it as described in our Privacy Policy.</p>

       <h4>5. Restrictions of Conduct and Content</h4>
       <p>In accessing and using the WEBSITE, you agree to abide by the following rules, restrictions, and limitations:</p>
       <ul>
         <li>You will not modify, translate, adapt, or reformat the WEBSITE.</li>
         <li>You will not decipher, decompile, disassemble, or reverse-engineer the software.</li>
         <li>You will not interfere with or circumvent any security feature of the WEBSITE.</li>
         <li>You will not use the WEBSITE to gain unauthorized access to any data systems or networks.</li>
         <li>You will not use the WEBSITE in any manner that could damage or impair our systems and networks.</li>
       </ul>

       <h4>5.4 Content Moderation</h4>
       <p>We have implemented a content moderation filter to ensure compliance with our Terms and Policies. If any content violates our terms, we reserve the right to manually review it and take appropriate action, including terminating the user's account.</p>

       <h4>6. Content Removal Policy</h4>
       <p>Please consult the Content Removal Policy document.</p>

       <h4>7. Blocked Content Policy</h4>
       <p>Please consult the Blocked Content Policy document.</p>

       <h4>8. Payments</h4>
       <p>Paid content areas of the WEBSITE are accessible only by subscribers. Subscription costs are indicated on the WEBSITE. After payment, users receive access to various features, including messaging and tokens for extended features.</p>

       <h4>9. Fulfillment Policy</h4>
       <p>Details on subscription cancellation and refund policies are described, including a 24-hour refund request window after payment.</p>

       <h4>10. No Guarantee of Accuracy</h4>
       <p>As content is generated by artificial intelligence, we cannot guarantee its accuracy, though we constantly work to improve our tools.</p>

       <h4>11. Liability</h4>
       <p>The WEBSITE is provided on an "as-is" and "as available" basis, and we disclaim all warranties to the fullest extent permissible under applicable law.</p>

       <h4>12. Links to Third Party Websites</h4>
       <p>The WEBSITE may include links to third-party websites. We assume no responsibility for any damages or losses resulting from the use of third-party websites.</p>

       <h4>13. Changes to Terms</h4>
       <p>We reserve the right to update these TOS and other Terms and Policies from time to time, with changes becoming effective immediately upon posting.</p>

       <h4>14. Termination</h4>
       <p>These TOS and any other Terms and Policies will continue in effect until terminated by either you or us. We may terminate your account for any reason at our sole discretion.</p>

       <h4>15. Miscellaneous</h4>
       <p>If any provision of these TOS is found to be unlawful, the remaining provisions will remain in effect.</p>

       <h4>16. Key Reminder</h4>
       <p>All conversations between users and AI Characters on Noodz.ai are entirely fictional and should be treated as such. The AI Characters are artificial intelligence characters designed to simulate human-like interactions, but they do not possess genuine emotions or intentions.</p>
     </LegalInformationPageWrapper>
  )
}

export default TermsOfServicePage;