import React, {FC, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react';
import TopSection from './components/TopSection';
import HomeList from './components/HomeList';
import HomeFooter from './components/HomeFooter';
import {useNavigate} from 'react-router-dom';
import {API} from '../../modules/api';
import AppStore from '../../store/AppStore';
import {IModel} from '../../modules/rest';
import {HeaderSecondary} from '../../containers/Header';
import {ReactSVG} from "react-svg";
import ellipse from "../../assets/icons/ellipse.svg";
import user from "../../assets/icons/user-btn.svg";
import Button from "../../components/Button";
import HomeRightHeader from "../../containers/Header/assets/components/HomeRightHeader";
import {isSub} from "../../modules/utils";
import {useTelegram} from "../../hooks/useTelegram";

interface Props {
}


const HomePage: FC<Props> = observer(() => {
  const {tgQueryId, tg} = useTelegram();
  const navigate = useNavigate();
  const [genderVisible, setGenderVisible] = useState(false);


  useLayoutEffect(() => {
    // setGenderVisible(false);
    // const onScroll = () => window.scrollY > 150 ? setGenderVisible(true) : setGenderVisible(false)
    // window.addEventListener('scroll', onScroll, {passive: true});
    // return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleAnswer = async (model: IModel) => {
    try {
      const res = await API.Models.startConversation(model.id);
      if (tgQueryId) {
        tg.close();
      } else {
        navigate(`/conversation/${res.id}`);
      }
    } catch (e: any) {
      // toast.error(e);
    }
  };


  return (
    <>
      <HeaderSecondary logo gender RightComponent={<HomeRightHeader/>}/>
      {/*<Header/>*/}
      <main>
        <div
          className={`home-page home__page_sub-${Number(isSub(AppStore.user))} home__page_ready-${Number(AppStore.ready)}`}>
          <TopSection onAnswer={handleAnswer}/>
          <HomeList onAnswer={handleAnswer}/>
          {/*<section>*/}
          {/*  <div className='container d-flex flex-column flex-lg-row px-4'>*/}
          {/*    <HomeFAQ/>*/}
          {/*    <HomeDescription/>*/}
          {/*  </div>*/}
          {/*</section>*/}
          <div className='btn__user-animate home__btn'>
            <Button href={'/create-ai'} size='lg' btnIcon>
              <div className='btn__user_animate-icon me-0'>
                <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                <ReactSVG src={ellipse} className='btn__user_animate-icon-ellipse'/>
                <ReactSVG src={user} className='react-icon'/>
              </div>
            </Button>
          </div>
          <HomeFooter/>
        </div>
      </main>

    </>
  );
});

export default HomePage;