import {action, makeAutoObservable, observable, runInAction} from 'mobx';
import {EDictionaryCollection, EFieldGroup, EGender, IUpdateProfileRequest, IUser} from '../modules/rest';
import {API} from '../modules/api';
import ChatStore from './ChatStore';
import {toast} from "react-toastify";

class AppStore {
  _ping: any;
  ready: boolean = false;
  dict?: Record<EGender, Record<EDictionaryCollection, string[]>>;
  user: IUser|null = null;
  gender: EGender = (localStorage.getItem('gender') as EGender) || EGender.Female;

  constructor() {
    const m = window.location.pathname.match(/^\/as\/([0-9A-z\.\-\_=]+)$/);
    if (m) {
      localStorage.setItem('token', m[1]);
    }
    makeAutoObservable(this, {
      ready: observable,
      user: observable,
      gender: observable,
      dict: observable,

      init: action,
      fetch: action,
      getUser: action,
      updateUser: action,
      login: action,
      logout: action,
      changeGender: action,
      getDict: action
    });
  }

  init = async (): Promise<any> => {
    clearTimeout(this._ping);
    this.getDict();
    try {
      let user;
      //@ts-ignore
      const tgInitData = window?.Telegram?.WebApp?.initData;
      if (tgInitData) {
        const {
          token,
          user
        } = await API.Users.loginWithTelegramApp({userData: tgInitData}, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
        API.setToken(token);
        window.localStorage.setItem('token', token);
        runInAction(() => {
          this.user = user;
        });
      } else {
        user = await this.getUser();
      }
      await this.fetch(user);
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        this.ready = true;
      });
    }
  };

  fetch = async (user?: IUser) => {
    if (!user) return;
    // await ChatStore.getList();
    this._ping = setInterval(this.getUser, 60000);
    runInAction(() => {
      this.ready = true;
    });
  };

  getUser = async (): Promise<IUser> => {
    const token = window.localStorage.getItem('token');
    try {
      let user: IUser;
      if (token) {
        API.setToken(token);
        user = await API.Users.getMe([EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      } else {
        const utm: any = localStorage.getItem('utm');
        const res = await API.Users.loginAsAnonymous({utm: utm ? JSON.parse(utm) : null}, [EFieldGroup.UserGender, EFieldGroup.UserSubscription]);
        user = res.user;
        API.setToken(res.token);
        localStorage.setItem('token', res.token);
      }
      if (user && !user?.gaClientId) {
        //@ts-ignore
        window.gtag('get', 'G-2WKTCV6YMR', 'client_id', (clientId) => {
          if (clientId) {
            API.Users.updateProfile({gaClientId: clientId});
          }
        });
      }
      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch (e) {
      throw e;
    }
  };

  getAnonymous = async () => {
    try {
      const utm: any = localStorage.getItem('utm') || {};
      const {token, user} = await API.Users.loginAsAnonymous({utm: JSON.parse(utm)}, [EFieldGroup.UserGender]);
      API.setToken(token);
      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch (e) {
      throw e;
    }
  };

  updateUser = async (user: IUpdateProfileRequest) => {
    if (!this.user) return;
    try {
      const res = await API.Users.updateProfile(user, [EFieldGroup.UserSubscription, EFieldGroup.UserGender]);
      runInAction(() => {
        this.user = res;
      });
      return res;
    } catch (e) {
      throw e;
    }
  };


  login = async (user: IUser) => {
    clearInterval(this._ping);
    runInAction(() => {
      this.user = user;
    });
    await this.fetch(user);
  };

  logout = (): void => {
    API.setToken(null);
    window.localStorage.removeItem('token');
    clearInterval(this._ping);
    runInAction(() => {
      this.user = null;
    });
    this.getUser();
    ChatStore.clean();
  };


  changeGender = (gender: EGender|'toggle') => {
    localStorage.removeItem('create-ai-step');
    localStorage.removeItem('create-ai-form');
    if (gender === 'toggle') {
      gender = this.gender === EGender.Male ? EGender.Female : EGender.Male;
    }
    localStorage.setItem('gender', gender);
    API.Analytics.logEvent({event: 'select_content', params: {content_type: 'gender', item_id: gender}});
    runInAction(() => {
      this.gender = gender as EGender;
    });
  };

  getDict = async () => {
    try {
      const res = await API.Dictionary.getFull();
      runInAction(() => {
        this.dict = res;
      });
    } catch (e) {
    }
  };
}

export default new AppStore();