import React, {FC} from 'react';
import logo from '../../../assets/images/logo.png';
import {Link, useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import {useTranslation} from "react-i18next";
import create_ai_small from "../../../assets/icons/create_ai_small.svg";
import Button from "../../../components/Button";
import crown from "../../../assets/icons/crown.svg";
import useLanguage from "../../../hooks/useLanguage";
import lang_svg from "../../../assets/icons/world.svg";
import {ELanguage} from "../../../modules/rest";
import {getLanguageName, isSub} from "../../../modules/utils";
import {Select} from "../../../components/FormControls";
import {openWarning18Modal} from "../../../modals/Settings/Warning18Modal";

interface Props {
}

const HomeFooter: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [lang, onChangeLanguage] = useLanguage()
  const navigate = useNavigate();


  return (
    <section className='home-footer-section'>
      <div className="home-footer">
        <div className="container">
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <img src={logo} alt="logo" className='home-footer-logo'/>
              <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE') || ''}}/>
              <div className='home__footer-btns'>
                <Button
                  iconAbsolute
                  href={'/create-ai'}
                  size='md'
                  title='CREATE_YOUR_AI'
                  icon={create_ai_small}
                />
                {(!isSub(AppStore.user) && AppStore.ready) &&
                  <Button
                    animate
                    iconAbsolute
                    btnType='secondary'
                    href={'/premium/subscription'}
                    size='md'
                    icon={crown}
                    title={'SUB_OFF_75'}
                  />
                }
              </div>
            </div>
            <div
              className='col-12 col-sm-6 col-lg-3 d-flex flex-column gap-3 d-flex flex-column align-items-center align-items-sm-start'>
              <div className='text-dark-14'>{t("COMPANY")}</div>
              <a href='https://aff.noodz.ai/' target='_blank'>{t('AFFILIATE_PROGRAM')}</a>
            </div>
            <div
              className='mt-3 mt-sm-0 col-12 col-sm-6 col-lg-3 d-flex flex-column gap-3 d-flex flex-column align-items-center align-items-sm-start'>
              <div className='text-dark-14'>{t("LEGAL")}</div>
              <Link to={'/legal-information/terms-of-service'}>{t('TERMS_OF_SERVICE')}</Link>
              <Link to={'/legal-information/underage-policy'}>{t('UNDERAGE_POLICY')}</Link>
              <Link to={'/legal-information/content-removal-policy'}
              >{t('CONTENT_REMOVAL_POLICY')}</Link>
              <Link to={'/legal-information/blocked-content-policy'}
              >{t('BLOCKED_CONTENT_POLICY')}</Link>
              <Link to={'/legal-information/privacy-policy'}>{t('PRIVACY_POLICY')}</Link>
            </div>

          </div>
          <div className="home__footer_bottom">
            <div className='text-dark-14'>© 2024 Noodz. All Rights Reserved</div>
            <div className='d-flex gap-3'>
              <Select
                icon={lang_svg}
                value={lang}
                onChange={(e) => onChangeLanguage(e.target.value)}
              >
                {Object.values(ELanguage).map(ln => (
                  <option value={ln} key={ln}>{getLanguageName(ln)}</option>
                ))}
              </Select>
              <div className='home__footer_18' onClick={() => openWarning18Modal()}>18+</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
})

export default HomeFooter;