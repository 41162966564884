import React, {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import StepNumber from './components/StepNumber';
import {EGender, IAsset, ICreateModelRequest, IVoice} from '../../modules/rest';
import {toast} from 'react-toastify';
import {API} from '../../modules/api';
import {useNavigate} from 'react-router-dom';
import AppStore from '../../store/AppStore';
import {HeaderSecondary} from '../../containers/Header';
import {useTranslation} from 'react-i18next';
import {personalityData, relationshipData} from './assets/modules/utils';
import GenderSelect from '../../components/GenderSelect';
import Step1Style from './components/Step1Style';
import StepTitle from './components/StepTitle';
import Step2Ethnicity from './components/Step2Ethnicity';
import Step3Age from './components/Step3Age';
import Step4HairStyle from './components/Step4HairStyle';
import Step5HairColor from './components/Step5HairColor';
import Step6BodyType from './components/Step6BodyType';
import Step7BreastSize from './components/Step7BreastSize';
import Step8ButtSize from './components/Step8ButtSize';
import Step9Personality from './components/Step9Personality';
import Step10Interests from './components/Step10Interests';
import Step11Relationship from './components/Step11Relationship';
import Step12Summary from './components/Step12Summary';
import {useTelegram} from "../../hooks/useTelegram";
import StepVoice from './components/StepVoice';
import {openModalNoMoney} from "../../modals/ModalNoMoney";
import {InputFile} from "../../components/FormControls";


const stepsMap: Record<EGender, ('summary'|keyof ICreateModelRequest)[]> = {
  [EGender.Female]: ['style', 'ethnicity', 'age', 'hairStyle', 'hairColor', 'bodyType', 'breastSize', 'buttSize', 'personality', 'voiceId', 'interests', 'relationship', 'summary'],
  [EGender.Male]: ['style', 'ethnicity', 'age', 'hairStyle', 'hairColor', 'bodyType', 'personality', 'voiceId', 'interests', 'relationship', 'summary']
};

interface Props {

}

const CreateAIPage: FC<Props> = observer(() => {
  useTelegram('/');
  const {t} = useTranslation();
  const navigate = useNavigate();

  const startForm = localStorage.getItem('create-ai-form');
  const startImage = localStorage.getItem('create-ai-image');

  const [ready, setReady] = useState(false);
  const [voices, setVoices] = useState<IVoice[]>();
  const [steps, setSteps] = useState(stepsMap);
  const [step, setStep] = useState<'summary'|keyof ICreateModelRequest>(localStorage.getItem('create-ai-step') as keyof ICreateModelRequest || 'style');
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState<IAsset|null>(startImage ? JSON.parse(startImage) : null);
  const [form, setForm] = useState<Partial<ICreateModelRequest>>(startForm ? JSON.parse(startForm) : {});

  useEffect(() => {
    if (form.style && form.ethnicity) {
      fetchVoices();
    }
  }, [AppStore.gender, form.style, form.ethnicity]);

  const removeVoiceStep = () => {
    const steps: any = {};
    steps[EGender.Female] = stepsMap[EGender.Female].filter(item => item !== 'voiceId');
    steps[EGender.Male] = stepsMap[EGender.Female].filter(item => item !== 'voiceId');
    setSteps(steps);
  };

  const fetchVoices = async () => {
    try {
      const res = await API.Voices.getVoices({
        gender: AppStore.gender,
        style: form.style,
        ethnicity: form.ethnicity,
        limit: 7
      });
      setVoices(res.data);
      if (!res.data?.length) {
        removeVoiceStep()
      } else {
        setSteps(stepsMap)
      }
    } catch (e) {
      removeVoiceStep()
    }
  };


  useEffect(() => {
    if (ready) handleReset();
    setReady(true);
  }, [AppStore.gender]);

  const handleReset = () => {
    localStorage.removeItem('create-ai-step');
    localStorage.removeItem('create-ai-form');
    localStorage.removeItem('create-image-image');
    setStep('style');
    setForm({});
    setImage(null);
  };

  const onStepChange = (nextStep: 'summary'|keyof ICreateModelRequest) => (data?: Partial<ICreateModelRequest>) => {
    if (step === 'style' && !nextStep) {
      localStorage.removeItem('create-ai-image');
      return setImage(null);
    }
    if (nextStep === 'ethnicity') {
      API.Analytics.logEvent({event: 'model_creation_start'});
    }

    localStorage.setItem('create-ai-step', nextStep);
    if (data) {
      localStorage.setItem('create-ai-image', JSON.stringify(image));
      setForm(prevState => {
        const newForm = {...prevState, ...(data || {})};
        localStorage.setItem('create-ai-form', JSON.stringify(newForm));
        return newForm;
      });
    }
    setStep(nextStep);
    window.scrollTo({top: 0, behavior: 'auto'});
  };

  const handleStepClick = (idx: number) => {
    const key = steps[gender][idx];
    if ((key === 'summary' && form.relationship) || form[key as keyof ICreateModelRequest]) setStep(steps[gender][idx]);
  }

  const handleChangeImage = (image?: IAsset|null) => {
    setImage(image as (IAsset|null));
    if (!image) {
      localStorage.removeItem('create-ai-image');
      setStep('style');
    }
  }

  const handleSubmit = async () => {
    if (loading) return;
    if (!AppStore.user?.id) {
      return navigate('/auth/register', {state: {prevPathname: '/create-ai'}});
    }
    try {
      setLoading(true);
      // @ts-ignore
      const personalityTitle: string = personalityData[form?.personality]?.title.toUpperCase();
      const res = await API.Models.create({
        ...form,
        imageId: image?.id || null,
        gender: AppStore.gender,
        // @ts-ignore
        relationship: t(relationshipData[form.relationship][AppStore.gender] || ''),
        personality: `${t(personalityTitle)} (${t(`${personalityTitle}_TEXT`)})`
      } as ICreateModelRequest);
      localStorage.removeItem('create-ai-step');
      localStorage.removeItem('create-ai-form');
      localStorage.removeItem('create-ai-image');

      navigate('/generate/model', {state: {task: res}});
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        if (AppStore.user?.isAnonymous) {
          navigate('/auth', {state: {prevPathname: '/create-ai'}});
        } else {
          openModalNoMoney('create-ai').then((path) => {
            if (path) navigate(path);
          });
        }
      } else {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const gender = AppStore.gender;
  const stepIdx = steps[gender].findIndex(item => item === step);
  return (
    <>
      <HeaderSecondary gender title={image ? 'FIND_DOUBLE' : `CREATE_MY_AI`} className='header__create_ai' coins>
        <StepNumber
          len={steps[gender].length}
          step={stepIdx}
          onClick={handleStepClick}
        />
      </HeaderSecondary>
      <main>
        <div className='blur__bg_container create__ai_page'>
          <h2 className='page-title'>
            <span className='text-accent'>{t(image ? 'FIND_DOUBLE' : `CREATE_MY_AI`)}</span>
          </h2>
          <GenderSelect onChange={handleReset} className='align-self-center'/>
          <div className='create__ai_steps-container'>
            {image
              ?
              <div className='create__ai_steps-image'>
                <InputFile
                  accept="image/jpeg"
                  preview
                  onChange={handleChangeImage}
                  value={image}
                />
                <p className='text-dark-14'>{t('UPLOAD_PHOTO_STEPS_TITLE')}</p>
              </div>
              :
              null
            }
            <StepNumber
              len={steps[gender].length}
              step={stepIdx}
              onClick={handleStepClick}
            />
          </div>
          <StepTitle
            onBack={onStepChange(steps[gender][stepIdx - 1])}
            onNext={onStepChange(steps[gender][stepIdx + 1])}
            onSubmit={handleSubmit}
            step={step}
            showNext={Boolean(form[step as keyof ICreateModelRequest])}
            hidePrev={step === 'style' && !image}
          />
          <div className='w-100'>
            {step === 'style' && <Step1Style
              onStepChange={onStepChange('ethnicity')}
              data={form}
              image={image}
              onChangeImage={handleChangeImage}
            />}
            {step === 'ethnicity' && <Step2Ethnicity onStepChange={onStepChange('age')} data={form}/>}
            {step === 'age' && <Step3Age onStepChange={onStepChange('hairStyle')} data={form}/>}
            {step === 'hairStyle' && <Step4HairStyle onStepChange={onStepChange('hairColor')} data={form}/>}
            {step === 'hairColor' && <Step5HairColor onStepChange={onStepChange('bodyType')} data={form}/>}
            {step === 'bodyType' &&
              <Step6BodyType onStepChange={onStepChange(gender === EGender.Female ? 'breastSize' : 'personality')}
                             data={form}/>}
            {step === 'breastSize' && <Step7BreastSize onStepChange={onStepChange('buttSize')} data={form}/>}
            {step === 'buttSize' && <Step8ButtSize onStepChange={onStepChange('personality')} data={form}/>}
            {step === 'personality' && <Step9Personality onStepChange={onStepChange('voiceId')} data={form}/>}
            {step === 'voiceId' && <StepVoice onStepChange={onStepChange('interests')} data={form} voices={voices}/>}
            {step === 'interests' && <Step10Interests onStepChange={onStepChange('relationship')} data={form}/>}
            {step === 'relationship' && <Step11Relationship onStepChange={onStepChange('summary')} data={form}/>}
            {step === 'summary' && <Step12Summary
              onStepChange={(step) => setStep(step)}
              onSubmit={handleSubmit}
              data={form}
              voices={voices}
              loading={loading}
              image={image}
            />}

          </div>
        </div>
      </main>
    </>
  );
});

export default CreateAIPage;
