import React, {useLayoutEffect, useRef, useState} from "react";
import moment from 'moment';

type TTimer = { days: number, hours: number, minutes: number, seconds: number }

const startTimeObj = Object.freeze({days: 0, hours: 0, minutes: 0, seconds: 0});

export const getTimeObjFromSeconds = (timestamp: number) => {
  let days = Math.floor(timestamp / (3600 * 24));
  let hours = Math.floor(timestamp % (3600 * 24) / 3600);
  let minutes = Math.floor(timestamp % 3600 / 60);
  let seconds = timestamp % 60;
  return {days, hours, minutes, seconds};
}

const useTimer = (date?: string|null): [time: TTimer, finished: boolean] => {
  const _timer: any = useRef(null);
  const [time, setTime] = useState<any>(startTimeObj);
  const [finished, setFinished] = useState(true);

  useLayoutEffect(() => {
    if (!date) return;
    const timestamp = moment(date).diff(moment.utc().local(), 'seconds') || 0;
    if (!timestamp || timestamp < 0) {
      setFinished(true);
      setTime(startTimeObj);
      return () => clearTimer();
    }
    startTimer(timestamp);
    return () => {
      clearTimer();
    }
  }, [date]);

  const clearTimer = () => {
    clearTimeout(_timer?.current);
  }

  const startTimer = (timestamp: number) => {
    if (timestamp < 0) {
      setFinished(true);
      setTime(startTimeObj)
      timestamp = 0;
      clearTimer();
    } else {
      setFinished(false);
      setTime(getTimeObjFromSeconds(timestamp));
      _timer.current = setTimeout(() => startTimer(timestamp - 1), 1000);
    }
  }
  return [time, finished];
}

export default useTimer;