import React, {FC, useState} from 'react';
import {ICreateModelRequest} from "../../../modules/rest";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import StepItem from "./StepItem";
import AppStore from "../../../store/AppStore";
import {relationshipData} from "../assets/modules/utils";
import StepTextareaWrapper from "../containers/StepTextareaWrapper";


interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
}

const Step10Interests: FC<Props> = observer(({onStepChange, data}) => {
  const interestsArr = AppStore.dict?.[AppStore.gender]['interests'];
  const [text, setText] = useState(interestsArr?.includes(data?.interests?.[0]!) ? '' : data?.interests?.[0]);

  const handleStepChange = (interests: ICreateModelRequest['interests']) => () => {
    onStepChange({interests});
  }

  return (
    <StepTextareaWrapper
      value={text}
      onChange={setText}
      onClick={handleStepChange([text] as ICreateModelRequest['interests'])}
      className='row g-3'
      placeholder={'INTERESTS_PLACEHOLDER'}
    >
      {interestsArr?.map((item, i) => (
        <div className='col-6 col-md-4' key={i}>
          <StepItem
            onClick={handleStepChange([item])}
            active={data?.interests?.includes(item)}
            className='step__item_personality'
          >
            <div className='text-14 item__personality_title'>
              {item}
            </div>
          </StepItem>
        </div>
      ))}
    </StepTextareaWrapper>
  );
})

export default Step10Interests;