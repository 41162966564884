import React, { FC,  useState,  useLayoutEffect } from 'react';
import {   ICreateModelRequest, IVoice } from '../../../../modules/rest';
import StepItem from '../StepItem';
import StepVoicePlayer from './StepVoicePlayer';

interface Props {
  onStepChange: (data?: Partial<ICreateModelRequest>) => void;
  data?: Partial<ICreateModelRequest>;
  voices?: IVoice[];
}

const StepVoice: FC<Props> = ({ onStepChange, data, voices }) => {
  const [activeVoiceId, setActiveVoiceId] = useState<number>();

  useLayoutEffect(() => {
    if(voices && !voices.length)  {
      onStepChange({ voiceId: null });
    }
  }, [voices]);

  const handleStepChange = (voiceId: ICreateModelRequest['voiceId']) => () => {
    onStepChange({ voiceId });
  };

  if (!voices?.length) return null;

  return (
    <>
      <div className='d-flex justify-content-center mt-3 mt-md-4 flex-wrap gap-2'>
       {voices.map((voice, i) => (
          <StepItem
            onClick={handleStepChange(voice.id)}
            active={data?.voiceId === voice.id}
            className='step__item_voice'
            size='small'
          >
            <StepVoicePlayer
              idx={i}
              voice={voice}
              activeVoiceId={activeVoiceId}
              onClick={setActiveVoiceId}
            />
          </StepItem>
      ))}
      </div>
    </>
  );
};

export default StepVoice;