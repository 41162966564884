import React, {FC} from 'react';
import {Textarea} from "../../../components/FormControls";
import prompt_svg from "../../../assets/icons/prompt.svg";
import Button from "../../../components/Button";
import chevron_right from "../../../assets/icons/chevron_right.svg";
import {useTranslation} from "react-i18next";

interface Props {
  value?: string;
  onChange: (value: string) => void;
  onClick: () => void;
  className?: string;
  placeholder: string;
  children?: any;
}

const StepTextareaWrapper: FC<Props> = ({value, className, onChange, children, onClick, placeholder}) => {
  const {t} = useTranslation();

  return (
    <div className={`create__with_textarea${value ? ' visible__textarea' : ''}`}>
      <div className={`${className || ''} create__with_textarea-content`}>
        {children}
      </div>
      <div className='create__ai_textarea'>
        <div className='create__ai_textarea-clear' onClick={() => onChange('')}>{t('SELECT_FROM_LIST')}</div>
        <Textarea
          placeholder={t(placeholder) || ''}
          label='YOUR_VERSION'
          icon={prompt_svg}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <Button
          onClick={onClick}
          icon={chevron_right}
          iconAbsolute
          title={'NEXT'}
        />
      </div>
    </div>
  );
}

export default StepTextareaWrapper;