import React from 'react';
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
} from "react-router-dom";
import {ToastContainer, Zoom} from 'react-toastify';
import {ErrorBoundary} from 'react-error-boundary';
import close_svg from './assets/icons/close.svg';
import './i18n'
import * as Sentry from "@sentry/react";
import 'swiper/css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './assets/styles/bootstrap.min.css'

import './assets/styles/fonts.css'
import './assets/styles/constants.css'
import './assets/styles/main.scss'
import './assets/styles/media.scss'

import App from './App'

import  './modals/assets/styles/modals.scss';
import  './modals/assets/styles/modals_media.scss';
import './assets/styles/tg-app.scss';

import ErrorFallback from "./pages/ErrorFallback";
import {ReactSVG} from "react-svg";
import {register} from "./serviceWorker";
import {isDev} from "./modules/api";

Sentry.init({
  dsn: "https://fdf7dd9c3927486e40d83a953cea3f08@o4508306600886272.ingest.de.sentry.io/4508306623234128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: isDev ? 'development' : 'production',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "noodz.ai", "mini-app-dev.noodz.ai"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
    <ToastContainer
      autoClose={2500}
      transition={Zoom}
      hideProgressBar
      position="top-center"
      icon={false}
      closeButton={<ReactSVG src={close_svg} className='react-icon'/>}
      closeOnClick
    />
  </BrowserRouter>
)

register();