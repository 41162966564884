import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EFieldGroup, EModelStyle, IModel} from "../modules/rest";
import {API} from "../modules/api";
import AppStore from "./AppStore";
import {toast} from "react-toastify";
import loading = toast.loading;

class HomeStore {
  ready: boolean = false;
  loading: boolean = false;
  style?: EModelStyle;
  randomModels: IModel[] = [];
  models?: IModel[];

  constructor() {
    makeAutoObservable(this, {
      ready: observable,
      loading: observable,
      style: observable,
      randomModels: observable,
      models: observable,

      init: action,
      onStyleChange: action,
    });

  }

  init = async (): Promise<any> => {
    try {
      const [randomModelsPager, modelsPager] = await Promise.all([
        API.Models.getTopModels({gender: AppStore.gender}, [EFieldGroup.ModelFull]),
        API.Models.getMainModels({gender: AppStore.gender, style: this.style}, [EFieldGroup.ModelFull])
      ])
      runInAction(() => {
        this.randomModels = randomModelsPager;
        this.models = modelsPager;
      })
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        this.ready = true;
      })
    }
  };

  onStyleChange = async (style?: EModelStyle) => {
    if (style === this.style || this.loading) return;
    try {
      runInAction(() => {
        this.style = style;
        this.loading = true;
      })
      const res = await API.Models.getMainModels({
        gender: AppStore.gender,
        style: this.style
      }, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.models = res
      })
      if(style) {
        await API.Analytics.logEvent({event: 'select_content', params: { content_type: 'style', item_id: style }});
      }
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

}

export default new HomeStore();