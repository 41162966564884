import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import React, {FC, useState} from "react";
import Modal from "react-responsive-modal";
import {ReactSVG} from "react-svg";
import close_svg from "../../assets/icons/close.svg";
import PrivacyPolicies from "../../pages/LegalInformationPage/pages/UnderagePolicy";
import TermsOfServicePage from "../../pages/LegalInformationPage/pages/TermsOfServicePage";
import CookiesPolicy from "../../pages/LegalInformationPage/pages/CookiesPolicy";
import {useTranslation} from "react-i18next";
import HomeStore from "../../store/HomeStore";
import Avatar from "../../components/Avatar";
import {thumbAvatar} from "../../modules/utils";
import {API} from "../../modules/api";


interface ConfirmModalProps {
  resolve(): void;
}

const Warning18Modal: FC<ConfirmModalProps> = ({resolve}) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState('');

  const onSubmit = () => {
    API.Analytics.logEvent({event: 'age18_click'});
    localStorage.setItem('confirm18+', '1');
    resolve();
  }

  const model = HomeStore.models?.[0];

  return (
    <div className='modal__container modal__adult_18'>
      <h4 className='text-accent mb-3'>{t('ADULT_ONLY')} <span className='badge-18'>(18+)</span></h4>
      {model
        ?
        <div className='modal__legacy_head'>
          <Avatar image={thumbAvatar(model.image?.id, 176)} size='xs'/>
          <div className='ps-2'>
            <div className='text-medium'>{model.name}</div>
            <div className="modal__legacy_message">{t('ADULT_ONLY_TITLE')}</div>
          </div>
        </div>
        :
        <div className='text-medium'>{t('ADULT_ONLY_TITLE')}</div>
      }
      <p className='mt-3 text-dark'>{t('ADULT_ONLY_SUBTITLE')}</p>
      <p className='text-dark'>{t('ADULT_ONLY_TEXT')}</p>
      <div className='d-flex gap-4 mt-3 mt-lg-4'>
        <div onClick={() => setModalVisible('privacy-policy')}
             className='text-14 text-accent cursor-pointer'>{t('PRIVACY_POLICY')}</div>
        <div onClick={() => setModalVisible('terms-of-service')}
             className='text-14 text-accent cursor-pointer'>{t('TERMS_OF_SERVICE')}</div>
        {/*<div onClick={() => setModalVisible('cookies-policy')} className='text-14 link'>{t('COOKIE')}</div>*/}
      </div>
      <div className='modal-footer'>
        <Button onClick={onSubmit} title={'I_AM_18'}/>
      </div>
      <Modal
        // animationDuration={0}
        open={Boolean(modalVisible)}
        onClose={() => setModalVisible('')}
        showCloseIcon={false}
        center
      >
        <div className='modal__container big my-4 modal__legacy'>
          <ReactSVG src={close_svg} className='modal-close' onClick={() => setModalVisible('')}/>
          {modalVisible === 'privacy-policy' && <PrivacyPolicies/>}
          {modalVisible === 'terms-of-service' && <TermsOfServicePage/>}
          {modalVisible === 'cookies-policy' && <CookiesPolicy/>}
        </div>
      </Modal>
    </div>
  );
};

const openWarning18Modal = (): Promise<boolean> => {
  return asyncModal(Warning18Modal, {}, {
    showCloseIcon: false,
    // animationDuration: 0,
    center: true,
    closeOnOverlayClick: false,
    classNames: {modalContainer: 'modal__bottom'}
  });
};

export {openWarning18Modal};
