import React, {FC, useState} from 'react';
import NameAge from '../NameAge';
import Button from '../Button';
import generate from '../../assets/icons/generate.svg';
import arrow from '../../assets/icons/chevron_top_16.svg';
import chat from '../../assets/icons/chat-add.svg';
import {IModel} from '../../modules/rest';
import {isMobile, isSmallDevice, thumb} from '../../modules/utils';
import ImageComponent from '../ImageComponent';
import {useTranslation} from 'react-i18next';
import {ReactSVG} from 'react-svg';
import {useNavigate} from "react-router-dom";
import {toJS} from "mobx";
import {openModelClickModal} from "../../modals/Model/ModelClickModal";

// const {t} = useTranslation();

interface Props {
  className?: string;
  chatBtn?: boolean;
  onClick?: () => void;
  hideAbout?: boolean;
  active?: boolean;
  disableCardClick?: boolean;
  lazy?: boolean;
  category?: string;
  model?: IModel;
  imageSize?: number;
  children?: any;
}


const ModelCard: FC<Props> = ({
                                className,
                                chatBtn,
                                onClick,
                                hideAbout,
                                active,
                                category,
                                model,
                                imageSize,
                                disableCardClick,
                                lazy,
                                children,
                              }) => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const [infoVisible, setInfoVisible] = useState(true)

  const handleToggleInfo = (e: any) => {
    e.stopPropagation();
    setInfoVisible(prevState => !prevState)
  };

  const handleClick = (type: 'card'|'chat'|'generate') => (e: any) => {
    e.stopPropagation();
    if (type === "card" && disableCardClick) return;
    if (isSmallDevice || isMobile) {
      openModelClickModal(model!).then((res) => {
        if (res === 'chat' && onClick) {
          onClick();
        } else if (res === 'generate') {
          navigate('/request-photo', {state: {model: toJS(model)}});
        }
      })
    } else {
      if ((type === 'chat' || type === 'card') && onClick) onClick();
      else if (type === 'generate') {
        e.stopPropagation();
        navigate('/request-photo', {state: {model: toJS(model)}});
      }
    }
  }

  if (!model) return <div className={`model-card${active ? ' active' : ''} ${className || ''}`}
                          onClick={handleClick('card')}></div>;

  return (
    <div className={`model-card${active ? ' active' : ''}${infoVisible ? ' info-visible' : ''} ${className || ''}`}
         onClick={handleClick('card')}>
      {category ?
        <div className='model-card-category'><span className='letter-uppercase'>{t(category.toUpperCase())}</span>
        </div> : null}
      {model.image ?
        <ImageComponent
          lazy={lazy}
          src={thumb(model?.image, imageSize || 800)}
          preloadSrc={thumb(model?.image, 50)}
          alt={'model_' + model.id}
        />
        : null}

      {!hideAbout &&
        <>
          <div className='model-card-about'>
            {children || null}
            <div className='model-card-about-title cursor-pointer' onClick={handleToggleInfo}>
              <NameAge name={model.firstName} age={model.age}/>
              <ReactSVG src={arrow} className='react-icon model-card-about-arrow'/>
            </div>
            {model.aboutLocal ?
              <div
                className='text-truncate-2 text-dark-14 model-card-about-text position-relative'>{model.aboutLocal}</div> : null}
            {chatBtn
              ?
              <div className='model-card-controls'>

                <Button
                  className='model-card-generate'
                  btnIcon
                  btnType='secondary'
                  icon={generate}
                  size='md'
                  onClick={handleClick('generate')}
                />
                <button className={'btn btn-md btn-primary model-card-chat'} onClick={handleClick('chat')}>
                  <ReactSVG src={chat} className='react-icon'/>
                  <span className='model-card-chat-text'>{t('CHAT')}</span>
                </button>
              </div>
              :
              null
            }

          </div>
        </>
      }

    </div>

  );
};

export default ModelCard;