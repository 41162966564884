import React, {FC, useEffect, useState} from 'react';
import PlayBtn from '../../../../components/PlayBtn';
import WavesurferPlayer from '@wavesurfer/react';
import {IVoice} from '../../../../modules/rest';
import {useTranslation} from 'react-i18next';

interface Props {
  voice?: IVoice;
  idx?: number;
  activeVoiceId?: number;
  onClick?: (id: number) => void;
  subTitle?: string;
}

const StepVoicePlayer: FC<Props> = ({voice, idx = 0, activeVoiceId, onClick, subTitle}) => {
  const {t} = useTranslation();
  const [wavesurfer, setWavesurfer] = useState<any>();

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (activeVoiceId !== voice?.id) {
      wavesurfer?.seekTo(0);
      wavesurfer?.pause();
    }
  }, [activeVoiceId]);

  const onReady = (ws: any) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    if (wavesurfer) {
      wavesurfer.seekTo(0);
      wavesurfer.playPause();
    }
  };

  const handleClick = (voiceId: number) => (e: any) => {
    e.stopPropagation();
    if (voiceId === activeVoiceId) {
      onPlayPause();
    } else {
      wavesurfer?.play();
      onClick && onClick(voiceId);
    }
  };

  if (!voice) return null;

  return (
    <>
      <PlayBtn onClick={handleClick(voice.id)} isPlaying={voice.id === activeVoiceId && isPlaying}/>
      <div className='flex-1 position-relative me-2 w-100'>
        <div className='step__item_voice-text'>
          {subTitle ? <div className='text-dark-14'>{t(subTitle)}</div> : null}
          <div className=''>{t('VOICE')} {idx + 1}</div>
        </div>
        <div className={`message-voice-container`}>
          <div className='message-wavesurfer'>
            <WavesurferPlayer
              height={32}
              waveColor={'#716F77'}
              progressColor={'#fff'}
              cursorColor={'transparent'}
              barRadius={5}
              // barWidth={4}
              // barGap={4}
              // progressColor='rgba(255, 255, 255, 1)'
              // cursorColor='red'
              url={voice.sample.url}
              // url={track}
              onReady={onReady}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StepVoicePlayer;